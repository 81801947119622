











































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { authorizationService } from "@/shared/services/AuthorizationService";
import { UserProfileItem } from "@/components/header/UserProfile/UserProfileItem";
import { RouterNames } from "@/router/routernames";
import { JwtService } from "@/shared/services/JwtService";
import { API } from "@/shared/api";
import usuarioModule from "@/store/modules/usuario-module";
import rolModule from "@/store/modules/rol-module";
import { rol } from "@/shared/dtos/rol";
@Component({
  components: {},
})
export default class UserProfile extends Vue {
  public image_default = false;
  public refre = false;
  public date = 2;
  public items: UserProfileItem[] = [
    {
      icon: "fad fa-sign-out-alt miprimary fa-lg ma-2",
      text: "",
      i18n: "Cerrar sesión",
      id: 0,
    },
    {
      icon: "fad fa-user-circle miprimary fa-lg ma-2",
      text: "",
      i18n: "Ver perfil",
      id: 1,
    },
  ];
  public menu: boolean = false;
  public rol_user: rol = new rol({ nombre: "" });
  public created() {
    /*VueInstanceService.vueinstance.$on(GlobalEvents.image_changed, () => {
      this.date++;
    });*/
    usuarioModule.getmiusuario().then(() => {
      rolModule.getrol(usuarioModule.mi_usuario.id_rol).then((x: rol) => {
        this.rol_user.nombre = x.nombre;
      });
    });
  }

  /**
   * get getImagen
   */
  public get getImagen() {
    return (
      API.webApiBaseImages +
      JwtService.jwtDecode().nameid +
      ".png?rnd=" +
      this.date
    );
  }
  public get userName() {
    return authorizationService.userName();
  }

  public get metadata_user() {
    return usuarioModule.mi_usuario;
  }

  public logout() {
    authorizationService.logout();
    this.$router.push("/login");
  }

  public opcion(item: UserProfileItem) {
    if (item.id === 0) {
      this.logout();
    } else if (item.id === 1) {
      this.$router.push({ name: RouterNames.mi_perfil });
    }
    this.menu = false;
  }
  public imageLoadError(event: any) {
    this.image_default = true;
  }
}
